import React from "react";
import Card from "../../components/ReviewCard";

import sectionShape from "../../assets/image/attendance-clock/png/section-overlay-1.png";

import reviewImageOne from "../../assets/image/attendance-clock/png/milo-abilities-maximization.png";
import reviewImageTwo from "../../assets/image/attendance-clock/png/nanolock-security.png";
import reviewImageThree from "../../assets/image/attendance-clock/png/augmedics.png";

const Review = ({className, ...rest}) => {
  const ReviewData = [
    {
      image: reviewImageTwo,
      name: "שוש קופרברג",
      title: "מנהלת משאבי אנוש",
      message: "אנו חברת סייבר, משתמשים בתוכנת פרופרטיים. הפתרון יעיל, מקצועי וידידותי למשתמש ראויה לציון תודעת השירות של צוות פרופרטיים. דנה ושרון מקפידות לתת מענה יעיל, מקצועי, זמין ומאיר פנים.",
    },
    {
      image: reviewImageOne,
      name: "ליאת מילוא",
      title: 'מנכ"ל',
      message: "מערכת נכונה ומדוייקת לצרכים הייחודיים של העסק, העושה נפלאות עבור המנהלים והמשתמשים. המחשבה שהושקעה, השידרוגים הנהדרים שעשיתם והשרות האישי והמהיר שאנחנו מקבלים מאפשרים לנו לעבוד ביעילות ולקבל מידע אודות ניצול שעות באון ליין.",
    },
    {
      image: reviewImageThree,
      name: "אילנית אבני",
      title: "מנהלת כספים",
      message: "התועלת שאנו מקבלים מהתוכנה היא הפקת דוחות במספר רב של חתכים הכוללים את דרישות המדען (רשות החדשנות). השירות מצויין! זמין מכיל ומיידי. אנו ממליצים!",
    },
  ];

  return (
    <div className={`position-relative ${className}`} {...rest}>
      <div
        className="absolute-top-right"
        data-aos="fade-left"
        data-aos-delay={500}
      >
        <img src={sectionShape} alt="" />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-19"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 mb-6">מרבית הלקוחות שלנו הגיעו דרך המלצות</h2>
              <p className="font-size-6 mb-0">
                אנחנו חושבים ופועלים אחרת. אכפת לנו ואנחנו משקיעים מחשבה ותכנון בכדי שהלקוחות יפיקו ערך מהמערכת.<br />כשהלקוחות באמת מרגישים כך, אנחנו יודעים שהצלחנו.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          data-aos="zoom-in"
          data-aos-delay={800}
        >
          {ReviewData.map(({ image, name, title, message }, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-xs-10">
                <Card image={image} name={name} title={title} key={index} message={message} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Review;
