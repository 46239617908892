import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/attendance-clock/Hero";
import Company from "../sections/attendance-clock/Company";
import Feature from "../sections/attendance-clock/Feature";
// import ContentOne from "../sections/attendance-clock/ContentOne";
// import ContentTwo from "../sections/attendance-clock/ContentTwo";
import Review from "../sections/attendance-clock/Review";
// import Pricing from "../sections/attendance-clock/Pricing";
import Contact from '../sections/attendance-clock/Contact';
import SEO from "../components/seo"

const AttendanceClock = ({location}) => {
  return (
    <>
      <SEO title="שעון נוכחות חכם ונוח" location={location} meta={[{name: 'keywords', content: 'שעון נוכחות, שעוני נוכחות, שעון נוכחות אינטרנטי, דיווח משימות נוכחות, היעדרות ונוכחות'}]} />
      <PageWrapper themeConfig={{
          footerStyle: "style3",
          footerClassName: "pt-13 pt-lg-25 pb-13 pb-lg-10",
      }}>
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        <Company className="pt-12 pb-9" />
        <Feature className="pt-4 pt-lg-18 pb-15 pb-lg-25 border-bottom border-default-color-3" />
        <Review className="bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
        <Contact className="bg-gradient-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
      </PageWrapper>
    </>
  );
};
export default AttendanceClock;
